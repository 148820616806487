import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";

// i18n
import { withNamespaces } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faDice } from "@fortawesome/free-solid-svg-icons";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

class BottomMenu extends Component {
  state = {};
  render() {
    const { t } = this.props;

    let menu1CSS = "bottom-menu bg-gray-9";
    let menu2CSS = "bottom-menu bg-gray-9";
    let menu3CSS = "bottom-menu bg-gray-9";
    let menu4CSS = "bottom-menu bg-gray-9";

    switch (this.props.menu) {
      case 1:
        menu1CSS = "bottom-menu-selected";
        break;
      case 2:
        menu2CSS = "bottom-menu-selected";

        break;
      case 3:
        menu3CSS = "bottom-menu-selected";

        break;
      case 4:
        menu4CSS = "bottom-menu-selected";

        break;
    }

    return (
      <Container fluid className="fixed-bottom">
        <Row className="text-center">
          <Col
            className={menu1CSS}
            as={Link}
            to="/gambling/home"
            onClick={() => {
              this.props.changeMenu(1);
            }}
          >
            <FontAwesomeIcon icon={faHome} className="font-size-10" /> <br />
            {t("menu_home")}
          </Col>
          <Col
            className={menu2CSS}
            as={Link}
            to=""
            target="_blank"
            onClick={(event) => {
              this.props.changeMenu(2);
              event.preventDefault();
              window.open("http://www.1stopdownload.com/");
            }}
          >
            <FontAwesomeIcon icon={faDice} className="font-size-10" />
            <br /> {t("menu_games")}
          </Col>
          <Col
            className={menu3CSS}
            as={Link}
            to="/gambling/promotion"
            onClick={() => {
              this.props.changeMenu(3);
            }}
          >
            <FontAwesomeIcon icon={faGift} className="font-size-10" />
            <br />
            {t("menu_promotion")}
          </Col>
          <Col
            className={menu4CSS}
            as={Link}
            to="/gambling/contact"
            onClick={() => {
              this.props.changeMenu(4);
            }}
          >
            <FontAwesomeIcon icon={faPhoneAlt} className="font-size-10" />
            <br />
            {t("menu_contact")}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withNamespaces()(BottomMenu);
