import React, { Component } from "react";

// i18n
import i18n from "./locales/i18n";
import { withNamespaces } from "react-i18next";
import LiveChat from "react-livechat";

// react-bootstrap
//import Button from "react-bootstrap/Container";

// component
// import Home from "./components/Home.js";
import Home from "./components/home/Home-sm.js";
import Promotion from "./components/promotion/Promotion.js";
import Contact from "./components/contact/Contact.js";
import HomeController from "./components/home/HomeController";
import BottomMenuController from "./components/bottomMenu/BottomMenuController";
import FooterController from "./components/footer/FooterController";
import NavbarController from "./components/navbar/NavbarController";

// react-router-dom
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

class App extends Component {
  state = {
    lang: "en",
  };

  render() {
    const { t } = this.props;

    const changeLanguage = (lng) => {
      console.log("call call" + lng);

      if (this.state.lang !== lng) {
        this.setState({ lang: lng });
        i18n.changeLanguage(lng);
      }
    };

    return (
      <BrowserRouter>
        <header>
          <NavbarController changeLanguage={changeLanguage} />
        </header>

        <Switch>
          <Route path="/gambling/home" component={HomeController} />
          <Route path="/gambling/promotion" component={Promotion} />
          <Route path="/gambling/contact" component={Contact} />
          <Redirect to="/gambling/home" component={Home} />
        </Switch>
        {/* //<ModalCenter /> */}

        {/* footer bar */}
        <footer>
          <FooterController />
        </footer>

        <div>
          <BottomMenuController />
        </div>

        <LiveChat license={12150348} />
      </BrowserRouter>
    );
  }
}

export default withNamespaces()(App);
