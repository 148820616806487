import React, { Component } from "react";

// i18n
//import i18n from "../../locales/i18n";
import { withNamespaces } from "react-i18next";

// react-bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";

import { Link } from "react-router-dom";

// images
import promo1 from "../../images/main-promo/main-promo-1.jpg";
import promo2 from "../../images/main-promo/main-promo-2.jpg";
import promo3 from "../../images/main-promo/main-promo-3.jpg";
import promo4 from "../../images/main-promo/main-promo-4.jpg";
import promo5 from "../../images/main-promo/main-promo-5.jpg";
import promo6 from "../../images/main-promo/main-promo-6.jpg";
import promo7 from "../../images/main-promo/main-promo-7.jpg";
import promo8 from "../../images/main-promo/main-promo-8.jpg";

import step1 from "../../images/step/step1.png";
import step2 from "../../images/step/step2.png";
import step3 from "../../images/step/step3.png";
import step4 from "../../images/step/step4.png";

import casino1 from "../../images/main-casino/casino1.jpg";
import casino2 from "../../images/main-casino/casino2.jpg";
import casino3 from "../../images/main-casino/casino3.jpg";
import casino4 from "../../images/main-casino/casino4.jpg";
import casino5 from "../../images/main-casino/casino5.jpg";
import casino6 from "../../images/main-casino/casino6.jpg";
import casino7 from "../../images/main-casino/casino7.jpg";
import casino8 from "../../images/main-casino/casino8.jpg";
import casino9 from "../../images/main-casino/casino9.jpg";

import slot1 from "../../images/main-slots/slot1.jpg";
import slot2 from "../../images/main-slots/slot2.jpg";
import slot3 from "../../images/main-slots/slot3.jpg";
import slot4 from "../../images/main-slots/slot4.jpg";
import slot5 from "../../images/main-slots/slot5.jpg";
import slot6 from "../../images/main-slots/slot6.jpg";
import slot7 from "../../images/main-slots/slot7.jpg";
import slot8 from "../../images/main-slots/slot8.jpg";
import slot9 from "../../images/main-slots/slot9.jpg";
import slot10 from "../../images/main-slots/slot10.jpg";
import slot11 from "../../images/main-slots/slot11.jpg";
import slot12 from "../../images/main-slots/slot12.jpg";

import download1 from "../../images/main-download/download1.png";
import download2 from "../../images/main-download/download2.png";

import jackpot from "../../images/jackpot-bg.gif";

// import girls1 from "../../images/girls/girls-1.jpeg";
// import girls2 from "../../images/girls/girls-2.jpeg";
// import girls3 from "../../images/girls/girls-3.jpeg";
// import girls4 from "../../images/girls/girls-4.jpeg";
// import girls5 from "../../images/girls/girls-5.jpeg";
// import girls6 from "../../images/girls/girls-6.jpeg";
// import girls7 from "../../images/girls/girls-7.jpeg";
// import girls8 from "../../images/girls/girls-8.jpeg";
// import girls9 from "../../images/girls/girls-9.jpeg";
// import girls10 from "../../images/girls/girls-10.jpeg";

import GameMenuCasino from "./GameMenuCasino-sm";
import GameMenuSports from "./GameMenuSports-sm";
import GameMenuSlots from "./GameMenuSlots-sm";
import GameMenuOthers from "./GameMenuOthers-sm";
import Button from "react-bootstrap/Button";

class Home extends Component {
  render() {
    const { t } = this.props;

    let gameContent;
    let gameContent1CSS = "home-menu bg-gray-8";
    let gameContent2CSS = "home-menu bg-gray-8";
    let gameContent3CSS = "home-menu bg-gray-8";
    let gameContent4CSS = "home-menu bg-gray-8";

    //console.log("111" + this.props.gameMenu);

    switch (this.props.gameMenu) {
      case 1:
        gameContent = <GameMenuCasino />;
        gameContent1CSS = "home-menu-selected";
        break;
      case 2:
        gameContent = <GameMenuSports />;
        gameContent2CSS = "home-menu-selected";

        break;
      case 3:
        gameContent = <GameMenuSlots />;
        gameContent3CSS = "home-menu-selected";

        break;
      case 4:
        gameContent = <GameMenuOthers />;
        gameContent4CSS = "home-menu-selected";

        break;

      default:
        gameContent = "default 111";
    }

    return (
      <div className="background-image">
        {/* modal */}
        {/* <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.show}
          onHide={this.modalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title> {this.props.t("contact_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={modalDisplayImage}
              alt="promo-image"
              className="img-fluid "
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.modalClose}>
              {this.props.t("close")}
            </Button>
            <Button variant="primary" onClick={this.modalClose}>
              Save Changes
            </Button> 
          </Modal.Footer>
        </Modal> */}
        {/* modal end */}
        <Carousel className="">
          <Carousel.Item>
            <img className="d-block w-100" src={promo1} alt="main-promo-1" />
            <Carousel.Caption>
              {/* <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={promo2} alt="main-promo-2" />

            <Carousel.Caption>
              {/* <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={promo3} alt="main-promo-3" />

            <Carousel.Caption>
              {/* <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={promo4} alt="main-promo-4" />

            <Carousel.Caption>
              {/* <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={promo5} alt="main-promo-5" />

            <Carousel.Caption>
              {/* <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={promo6} alt="main-promo-6" />

            <Carousel.Caption>
              {/* <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={promo7} alt="main-promo-7" />

            <Carousel.Caption>
              {/* <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={promo8} alt="main-promo-8" />

            <Carousel.Caption>
              {/* <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <Container className="bg-step">
          <Row>
            <Col xs={{ span: 10, offset: 1 }} className="img-center">
              <img src={step1} alt="step1" />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 10, offset: 1 }} className="img-center">
              <img src={step2} alt="step1" />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 10, offset: 1 }} className="img-center">
              <img src={step3} alt="step3" />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 10, offset: 1 }} className="img-center">
              <img src={step4} alt="step4" />
            </Col>
          </Row>
        </Container>
        <Container fluid className="bg-step">
          <br />
          <Row>
            <Col>
              <img src={jackpot} alt="" className="img-fluid" />
              <p className="text-center text-white m-top">
                MYR 171,856,157,128
              </p>
            </Col>
          </Row>
          <br />
        </Container>
        <Container fluid>
          <Row className="text-center">
            <Col
              className={gameContent1CSS}
              onClick={() => this.props.changeGameMenu(1)}
            >
              {t("casino")}
            </Col>
            <Col
              className={gameContent2CSS}
              onClick={() => this.props.changeGameMenu(2)}
            >
              {t("sports")}
            </Col>
            <Col
              className={gameContent3CSS}
              onClick={() => this.props.changeGameMenu(3)}
            >
              {t("slots")}
            </Col>
            <Col
              className={gameContent4CSS}
              onClick={() => this.props.changeGameMenu(4)}
            >
              {t("others")}
            </Col>
          </Row>
        </Container>
        <Container fluid className="background-image">
          {gameContent}
        </Container>

        <Container className="bg-step">
          <br />
          <Row>
            <Col>
              <h4 className="text-orange text-center">Live Casino Platform</h4>
            </Col>
          </Row>
          <br />
        </Container>
        <Container className="background-image">
          <br />
          <Row>
            <Col>
              <img src={casino1} alt="casino1" />
            </Col>
            <Col>
              <img src={casino2} alt="casino2" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <img src={casino3} alt="casino1" />
            </Col>
            <Col>
              <img src={casino4} alt="casino2" />
            </Col>
          </Row>
          <br />

          <Row>
            <Col>
              <img src={casino5} alt="casino1" />
            </Col>
            <Col>
              <img src={casino6} alt="casino2" />
            </Col>
          </Row>
          <br />

          <Row>
            <Col>
              <img src={casino7} alt="casino1" />
            </Col>
            <Col>
              <img src={casino8} alt="casino2" />
            </Col>
          </Row>
          <br />

          <Row>
            <Col>
              <img src={casino9} alt="casino1" />
            </Col>
            {/* <Col>
              <img src={casino9} alt="casino2" />
            </Col> */}
          </Row>
          <br />
        </Container>
        <Container className="bg-step">
          <br />
          <Row>
            <Col>
              <h4 className="text-orange text-center">Slot Game Platform</h4>
            </Col>
          </Row>

          <br />
        </Container>

        <Container className="background-image">
          <br />
          <Row>
            <Col>
              <img src={slot1} alt="slot1" />
            </Col>
            <Col>
              <img src={slot2} alt="slot2" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <img src={slot3} alt="slot3" />
            </Col>
            <Col>
              <img src={slot4} alt="slot4" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <img src={slot5} alt="slot5" />
            </Col>
            <Col>
              <img src={slot6} alt="slot6" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <img src={slot7} alt="slot7" />
            </Col>
            <Col>
              <img src={slot8} alt="slot8" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <img src={slot9} alt="slot9" />
            </Col>
            <Col>
              <img src={slot10} alt="slot10" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <img src={slot11} alt="slot11" />
            </Col>
            <Col>
              <img src={slot12} alt="slot12" />
            </Col>
          </Row>
          <br />
        </Container>

        <Container className="bg-step">
          <br />
          <Row>
            <Col>
              <img src={download1} alt="download1" className="img-fluid" />
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={download2} alt="download2" className="img-fluid" />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button
                className="btn-1"
                as={Link}
                to=""
                target="_blank"
                onClick={(event) => {
                  event.preventDefault();
                  window.open("http://www.1stopdownload.com/");
                }}
              >
                Download Now
              </Button>
            </Col>
          </Row>
          <br />
        </Container>

        <Container className="bg-step text-white">
          <br />
          <h5 className="text-center">
            Best Casino Malaysia Online - Trusted and Fun Online Gambling
            Malaysia Site
          </h5>
          <br />
          <p className="text-justify">
            It all started in 1965 with the Genting Highlands Resort. Casino
            gambling has been a Malaysian tradition since Genting Casino was
            first launched. Though, the casino’s first online gambling venture
            is fairly recent, the gambling den is one of the most experienced
            Malaysia Online Casino in the industry. The first presence of
            Genting was in 2008 through the Circus Casino. The online platform
            was later rebranded as the Genting Online Casino in 2010. The
            Internet gambling has provided Malaysians with a unique way of
            improving their casino experience without necessarily visiting a
            gambling house. Live dealer gaming has seen the rise of top Malaysia
            online casino example KK Casino, which is one of the most trusted
            online casino in Malaysia. The most popular internet casinos games
            include slots, 4D, live casino and sportsbook. Live casinos gives
            you a chance to win blackjack, baccarat, roulette at the comfort of
            your home. A few clicks here and there and there is all it takes
            nowadays.
          </p>
          <br />
          <h5 className="text-center">
            Best Online Gambling Malaysia Casino Site
          </h5>
          <br />
          <p className="text-justify">
            Are you a passionate online casino player in Malaysia? Then you know
            it is not easy finding the ideal gambling house online. There are
            just too many options out there. You are probably exhausted just
            preliminarily googling this stuff.
          </p>
          <p className="text-justify">
            Which ones are licensed? And, most importantly, which ones provide
            the biggest and most trusted bonuses. That is where our trusted
            casino online comes in. Our site has all the key information you
            need to choose the best possible casino offer at any time.
          </p>
          <p className="text-justify">
            Our website has the best online casino in Malaysia. You get a wide
            range of games to play, with complete versions. You can easily gain
            experience in most games that you usually play at a traditional
            gambling house. Our internet casino games can win you real money.
            You will get all the classic games that you have come to know and
            admire in gambling.
          </p>
          <p className="text-justify">
            These includes all live casino Malaysia online games, such as:
          </p>
          <li>Roulette Live</li>
          <li>VIP Baccarat</li>
          <li>Sic Bo</li>
          <li>French and VIP Roulette</li>
          <li>No Commission Mini Baccarat</li>
          <li>No Commission 7 Seats Baccarat</li>
          <li>7 Seat Baccarat</li>
          <li>Live 7 Seat Baccarat</li>
          <li>DragonTiger</li>
          <li>Blackjack </li>
          <li> Ultimate Holdem</li>
          <br />
          <p className="text-justify">
            Our site is powered by the best gaming software that is easily
            accessible as long as you have a decent mobile or computer device
            and an internet connection. Be rest assured that you will get
            farfetched graphics as well as smooth gameplay that you will come to
            love and expect while playing our casino games. You can earn free
            credits & promotion. You get the greatest rewards that you can. We
            are the best online casino Malaysia because we offer 99% payout on
            all games. We know why you are here and we have just what you have
            been looking for. The best Malaysia online casino with free sign up
            bonus. As you may well know, offering frequent and generous rewards
            is crucial to safeguarding our competitive edge.
          </p>
          <p className="text-justify">
            As a new player on our site for the first time, you should expect to
            get trusted Welcome Bonus or our Deposit Bonus. We give you a fair
            chance to try us out. As the best online casino Malaysia, we are
            well-known for providing the highest payouts in the industry. We
            maintain the highest standard of all our games on offer to boost
            your odds of winning. Our gaming experience controls can be mastered
            in the shortest time possible without any difficulties.
          </p>
          <h5 className="text-center">Best Casino Malaysia Online Games!</h5>
          <h5 className="text-center">Live Casino</h5>
          <p className="text-justify">
            We offer everything you are looking for with several games to choose
            from. Everybody is a winner at our live casino. Experience the
            thrill of live casinos online wagering for real money or fun. There
            are award-winning graphics with guaranteed secure transactions.
            There’re quite a number of online casino software. Our live casino
            Malaysia features the most popular game developers, including:
          </p>
          <li>Play Tech</li>
          <li>Game Play</li>
          <li>SA Gaming</li>
          <li>Asia Gaming </li>
          <br />
          <h5 className="text-center">Types of Live Casino</h5>
          <p>- Roulette </p>
          <p className="text-justify">
            Live Roulette is a real money winner with a table layout and
            standardized rules. It is played with a thirty-seven-pocket wheel.
            This game has a remarkably low house advantage of around 2.7%. You
            can put your skills to the test with the variety of betting options
            at your disposal. You should know that Live Roulette does not have
            special bets functionality. This is not a rule exception, but more
            of an interface limitation. Acceptable regular inside bets include
            35:1 for a straight-up and 5:1 for a six line. But the payouts for
            outside bets are naturally lower because of their high outcome
            probability. The limits are displayed automatically with respect to
            the bet type. All you have to do for the limit indication to appear
            is move your mouse over your desired chips.
          </p>
          <p>- Baccarat </p>
          <p className="text-justify">
            Live Baccarat combines an electronic baccarat game with a live
            dealer. Sitting on a podium in front of a bank of electronically
            linked individual gaming terminals. Our croupier deals a hand of
            baccarat, the cards are displayed both on a large video screen in
            the front of the casino and on small screens at the individual
            terminals. Online baccarat is surely safe and trustworthy, but you
            may have a few reasons not to trust a computer. On the other hand,
            live baccarat allows you to watch every move the dealer makes. Other
            types of live online casino games include: Dragon Tiger, Sicbo, and
            Super Fantan.
          </p>
          <h5 className="text-center">Slot Online</h5>
          <p className="text-justify">
            The beauty of slot online at our website is that the games are
            computerized. Therefore, they are designed in different ways and
            gameplay styles. There are different types of online slot in
            Malaysia, including 3 reel, 5 reel, progressive, mobile, mega spin,
            and multiplier to mention a few.
          </p>
          <h5 className="text-center">Types of Online Slot</h5>
          <p>- 3 Reel Slot </p>
          <p className="text-justify">
            The traditional 3 reel slots normally have 3 or 5 paylines. Classic
            slot games consist only of three reels without bonus games as you
            would with modern slots. It is the easiest slot machine you can
            play. Three rows spin and you have to match symbols along the
            bottom, middle, and top line to win.
          </p>
          <p>- 5 Reel Slot </p>
          <p className="text-justify">
            This is probably the most popular Online Slot in Malaysia. It is the
            carrier of highly engaging and very interesting mini-games, bonuses,
            and themes known today. This type has 5 reels instead of 3. The
            number of paylines usually differ from one game to another. But the
            standard number on most 5 reel slots is 20 paylines. Unlike 3 reel
            types, 5 reel slots provide you with more winning opportunities.
            However, the house edge allows for less big wins and frequent small
            wins.
          </p>
          <p>- Progressive Slot </p>
          <p className="text-justify">
            The progressive slots are among the most popular casino games played
            today. Some penny slots and some high limit slots are normally
            progressive. In most cases, gamblers who play on a progressive slot
            will win a huge jackpot; whether they play a max bet or single bet.
            You may game on a progressive slot once a month, but generally a
            progressive slot is a money maker for the house except from time to
            time.
          </p>
          <p>- Mobile Slot </p>
          <p className="text-justify">
            Are you tired playing slots on your PC? If so, you have an
            alternative, mobile slots, which can be played on Windows, iPhone,
            or Android mobile devices. Mobile casino websites are chock full of
            exclusive bonuses, in addition to free casino sign up bonus offers.
          </p>
          <p>- Mega Spin Slot </p>
          <p className="text-justify">
            Mega spin slots allow you to play a number of games at once and on
            the same screen. Some of the slots in the series may feature 3 reels
            and a single payline. Usually, one mega spin slot is connected to
            the major millions jackpot. This enables you to hit an inconceivably
            large progressive jackpot in a single lucky spin. A feature unique
            to mega spin slots is the fact that they allow you to play 4, 6, or
            9 sets of reels on one screen.
          </p>
          <p>- Multiplier Slot </p>
          <p className="text-justify">
            A multiplier is a game where the more bets you place, the more money
            you earn. Each additional bet multiplies the value of winnings. The
            most popular game developers for online slots are Play Tech, Game
            Play, SA Gaming, and Asian Gaming.We have slots from different game
            developers. All you have to do is select a game platform to view
            thel latest available slots. For example, some of the most popular
            slots provided by Game Play include: Baseball, Bikini Beach, Boxing,
            Candylicious, Casino Royale, Deep Blue, and Desert Oasis to mention
            a few.
          </p>
          <h5 className="text-center">Sports</h5>
          <p className="text-justify">
            Sports betting has become increasingly popular in Malaysia, with a
            greater diversity of Sportbook Asia now on offer on our sports
            betting website. You can now watch sporting events on your TV in the
            comfort of your own home, and simultaneously place bets on the
            outcome through our Online Sportsbook. Sports Betting in Malaysia
            allows you to gamble on all well-recognized sports contests under
            one roof, including football and basketball, premier league.
          </p>
          <p className="text-justify">
            The recent development on online casino sports betting and is
            in-play betting where you can post bets online gaming during a live
            sporting event. The best online sports betting features available
            for you in in-play betting may include: results of the match, the
            correct score, and the name of the next player to score among other
            things.There is a wide selection of sports betting platforms, such
            as:
          </p>

          <li>Vic996 Sports</li>
          <li>IBC Sports</li>
          <li>SBO Sports</li>
          <li>iSports</li>
          <li>sSports</li>
          <li>wSports</li>

          <h5 className="text-center">4D</h5>
          <p className="text-justify">
            Lotteryis part of the rich culture in Malaysia and Singapore, and
            one of the most popular lotteries is 4D. In fact, people in Malaysia
            and Singapore queue at popular outlets to wager on permutations. You
            can become a millionaire overnight with 4D.
          </p>
          <p className="text-justify">
            The major 4D providers in Malaysia are:
          </p>
          <li>Sports Toto</li>
          <li>Magnum 4D</li>
          <li>Da Ma Cai</li>
          <br />
          <p className="text-justify">
            These are independent private enterprises that are qualified and
            approved by the Malaysian Government. 4D lottery draws daily in
            Malaysia, and some on every Sunday, Wednesday, and Saturday. These
            draws usually concur with 4D Singapore draws. The most popular
            Sports Toto Betting is Toto 4D, which has five prizes: first prize,
            second prize, third prize, special prize, and consolation prize. You
            win a given prize, if your winning numbers match the Toto 4D result
            with respect to the rules of the game.
          </p>
          <h5 className="text-center">Experience on Playing in KK Casino</h5>

          <p className="text-justify">
            We have the best customer service in the region.. KK Casino as the
            best online gambling Malaysia, with casino malaysia online games -
            live casino, Sportsbook, slots, and 4D that covers it all. It is our
            primary objective to ensure you get everything under one roof, and
            you can deposit and withdraw money quick with us. Our website
            supports all the popular banks in the region, including: AmBank,
            Bank Islam, BSN Bank, CIMB Bank, HLB Bank, Maybank, RHB Bank, and
            Public Bank. Unlike conventional online betting casinos, we have a
            super-fast service speed. If you have an issue with our site, you
            won’t have to wait hours or days to get the necessary support you
            need. Our customer service agents have excellent language skills.
            You can easily communicate with us in English, Bahasa Melayu and
            中文. Our support team consists of natives and highly trained
            personnel. We take great pride in our service quality because our
            agents are easily accessible, and they will respond to your question
            or provide a solution for your problem in a matter of minutes. We
            have a number of service methods you can reach us through, and they
            include the following:
          </p>
          <li>Direct Calls at +60 16-672 8924</li>
          <li>WhatsApp at +60 11-5881 2260</li>
          <li>WeChat at kkbetcs888</li>
          <li>Telegram at +60 11-5881 2260</li>
          <br />
          <p className="text-justify">
            Our website has the best online gambling malaysia games in the
            market. Unlike other online casinos, we only deal with the most
            popular game developers in the industry. Our game developers have a
            solid reputation of producing the most reliable slot games. Each
            platform in KK Casino malaysia online offers you a wide selection of
            slots to choose from. The featured game developers provide slot
            themes that will have you hooked to the game. They provide themes
            that you love the most. There is a theme for everyone; whether you
            are a boxing, baseball, underwater world, golf, movie, roulette, or
            football fan among others. We have great promotion on our website,
            including: new member bonus, unlimited deposit bonus, daily bonus,
            ultimate slot bonus, lucky lottery, fortune return bonus, birthday
            bonus, daily rebate slot, daily sportsbook rebate, and daily rebate
            live casino.
          </p>
          <br />
        </Container>

        {/* <Container fluid>
          <Row>
            <Col>
              <img src={girls1} alt="" className="img-fluid" />
            </Col>
            <Col>
              <img src={girls2} alt="" className="img-fluid" />
            </Col>
            <Col>
              <img src={girls3} alt="" className="img-fluid" />
            </Col>
            <Col>
              <img src={girls4} alt="" className="img-fluid" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <img src={girls5} alt="" className="img-fluid" />
            </Col>
            <Col>
              <img src={girls6} alt="" className="img-fluid" />
            </Col>
            <Col>
              <img src={girls7} alt="" className="img-fluid" />
            </Col>
            <Col>
              <img src={girls8} alt="" className="img-fluid" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <img src={girls9} alt="" className="img-fluid" />
            </Col>
            <Col>
              <img src={girls10} alt="" className="img-fluid" />
            </Col>
            <Col><img src={girls7} alt="" className="img-fluid" /></Col>
            <Col><img src={girls8} alt="" className="img-fluid" /></Col>
          </Row>
          <br />
        </Container> */}
        {/* why choose us */}
        {/* <Container className="mb-1 font-family-1">
          <Col lg className="background-image-gray text-center p-2 ">
            <span className=" text-white  font-size-4 font-family-1">
              {this.props.t("why_choose_us")}
            </span>
          </Col>
        </Container> */}
        {/* <Container className="">
          <Row className="px-2 mb-3 font-family-1">
            <Col
              lg
              className="background-image-yellow text-center font-size-2 text-white m-2 p-3"
            >
              <FontAwesomeIcon
                icon={faCoins}
                inverse
                className="font-size-9 mb-2"
              />
              <br />
              {this.props.t("special_welcome_bonus")}
            </Col>
            <Col
              lg
              className="background-image-yellow text-center font-size-2 text-white m-2 p-3"
            >
              <FontAwesomeIcon
                icon={faHandshake}
                inverse
                className="font-size-9 mb-2"
              />
              <br />
              {this.props.t("transparent_payment_system")}
            </Col>
            <Col
              lg
              className="background-image-yellow text-center font-size-2 text-white m-2 p-3"
            >
              <FontAwesomeIcon
                icon={faPhoneAlt}
                inverse
                className="font-size-9 mb-2"
              />
              <br />
              {this.props.t("customer_support")}
            </Col>
            <Col
              lg
              className="background-image-yellow text-center font-size-2 text-white m-2 p-3"
            >
              <FontAwesomeIcon
                icon={faUserTie}
                inverse
                className="font-size-9 mb-2"
              />
              <br />
              {this.props.t("friendly_customer_support")}
            </Col>
          </Row>
        </Container> */}
        {/* end why choose us */}
        {/* content */}
        {/* <Container className="">
          <span className="text-white text-justify">
            <h3>The Top Slot Games and Live Casino Malaysia</h3>
            <p className="text-justify">
              KK Casino offers you so many options, from slot games to live
              dealer games. It’s enough to feel like you’re right in the middle
              of the action. Pick the right website that plays for real money
              betting. There is no doubt that the future of gambling and casino
              games is here. Live dealer games and online slot machines bring
              the excitement of the casino where ever you are.
            </p>
            <p>
              KK Casino have a lot promotions and bonuses than Offline Casino,
              let's do a try today. Are you ready to start playing? Take a look
              at these Live Casino games and try your luck today.
            </p>
            <h3>What Are the Top Online Casino Games Malaysia?</h3>
            <p>
              There are many casino games that you can play. The top ones
              include 918kiss slot machines, which usually bring in the most
              winning for players in Casino Malaysia. Slot games are less
              intimidating than other games, and they appeal to beginners and
              long-time players, 918kiss and Luckypalace is the hottest slot
              games in Malaysia. Live dealer casino games allow you to play
              games like Blackjack or Sicbo. You’ll also find baccarat or
              roulette, too. These games are run in real-time by a live dealer
              (yes, a human being). They pretty much run the room, in the same
              way that a moderator would run a debate or community forum. In
              this instance, they work with real casino tables and equipment,
              such as a roulette wheel. Live dealer games give you the fun of
              being at the casino without having to be there. You can interact
              with the dealer just like you would at the casino. How do you know
              if you won? Online casinos will use software on the backend of the
              site that uses random number generators. That ensures a fair
              playing experience for everyone. Some online casinos will offer
              other ways to gamble, such as with bingo games or sports betting
            </p>
          </span>
          <br />
        </Container> */}
        {/* end content */}
        {/* games */}
        {/* <Container className="mb-1 ">
          <Col
            lg
            className="background-image-gray text-center font-size-4 text-white p-2 font-family-1"
          >
            {this.props.t("online_casino_games")}
          </Col>
        </Container>

        <Container className="">
          <Row className="px-2 ">
            <Col xs={6} md={4} className="p-3">
              <img
                className="d-block w-100"
                src={pussy888Logo}
                alt="First slide"
              />
            </Col>
            <Col xs={6} md={4} className="p-3">
              <img className="d-block w-100" src={xe88Logo} alt="First slide" />
            </Col>
            <Col xs={6} md={4} className="p-3">
              <img
                className="d-block w-100"
                src={jokerLogo}
                alt="First slide"
              />
            </Col>
            <Col xs={6} md={4} className="p-3">
              <img
                className="d-block w-100"
                src={kiss918Logo}
                alt="First slide"
              />
            </Col>
            <Col xs={6} md={4} className="p-3">
              <img
                className="d-block w-100"
                src={mega888Logo}
                alt="First slide"
              />
            </Col>
            <Col xs={6} md={4} className="p-3">
              <img
                className="d-block w-100"
                src={maxbetLogo}
                alt="First slide"
              />
            </Col>
          </Row>
        </Container> */}
        {/* end games */}
      </div>
    );
  }
}

export default withNamespaces()(Home);
