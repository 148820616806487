import React, { Component } from "react";

// Load logo image path
import logo from "../../images/logo/kk_casino_logo.svg";

// i18n
import { withNamespaces } from "react-i18next";

// react-router-dom
import { Link } from "react-router-dom";

// react-bootstrap
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

class Navbar1 extends Component {
  render() {
    const { t } = this.props;

    return (
      <Navbar expand="lg" className="font-family-1 bg-step">
        <Navbar.Brand as={Link} to="/gambling/home">
          {/* Logo */}
          <img src={logo} className="img-fluid" alt="kk-casino-logo" />
          {/* Text */}
          {/* EliteStudio */}
        </Navbar.Brand>

        <Nav className="m-auto bbb ">
          <NavDropdown title={t("menu_language")} className="aaa ">
            <NavDropdown.Item onClick={() => this.props.changeLanguage("en")}>
              {t("english")}
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => this.props.changeLanguage("cn_simp")}
            >
              {t("chinese")}
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => this.props.changeLanguage("my")}>
              {t("malay")}
            </NavDropdown.Item>

            {/* <NavDropdown.Divider /> */}
          </NavDropdown>
        </Nav>

        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="" id="basic-navbar-nav"> */}
        {/* left-menu */}
        {/* <Nav className="mr-auto">
            {/* <Nav.Link href="#home">Home</Nav.Link>

            <Nav.Link as={Link} to="/gambling/home" className="mx-2 ">
              <span className="font-size-3 ">{this.props.t("menu_home")}</span>
            </Nav.Link>
            <Link
              to="route"
              target="_blank"
              onClick={(event) => {
                event.preventDefault();
                window.open(this.makeHref("route"));
              }}
            />
            <Nav.Link
              as={Link}
              to=""
              target="_blank"
              onClick={(event) => {
                event.preventDefault();
                window.open("http://www.1stopdownload.com/");
              }}
              className="mx-2 "
            >
              <span className="font-size-3">{this.props.t("menu_games")}</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/gambling/promotion" className="mx-2 ">
              <span className="font-size-3 ">
                {this.props.t("menu_promotion")}
              </span>
            </Nav.Link>
            <Nav.Link as={Link} to="/gambling/contact" className="mx-2">
              <span className="font-size-3">
                {this.props.t("menu_contact")}
              </span>
            </Nav.Link>
          </Nav> */}
        {/* right-menu */}
        {/* </Navbar.Collapse> */}
      </Navbar>
    );
  }
}

export default withNamespaces()(Navbar1);
