import React, { Component } from "react";

import Table from "react-bootstrap/Table";

class ModalText8 extends Component {
  state = {};
  render() {
    return (
      <div>
        <br />
        <h3>Recommend Bonus</h3>

        <h5>Terms & Conditions</h5>
        <p>
          1) The promotion is open for all KKBET players.
          <br />
          2) Players have to make a recommend player minimum deposit of MYR 30.
          <br />
          3) Maximum claim for this promotion is MYR 300.
        </p>

        <p>
          How to claim:
          <br />
          Recommend a Friends for Deposit,
          <br />
          After Deposit Please Contact Customers Service to Claim
        </p>

        <p>
          Example:
          <br />
          Deposit 30 - 10
          <br />
          Deposit 50 - 20
          <br />
          Deposit 100 – 30
          <br />
          Deposit 200 - 60
        </p>

        <p>
          Minimum & Maximum cashout requirement = Rollover x3
          <br />
          Example:
          <br />
          Bonus 150 / Rollover x3 = 450
          <br />
          Total Betting Spin RM 450
        </p>

        <p>
          1) For the purpose of the Promotion, only wagers in KKBET that
          generate a win/loss return will contribute to the rollover
          requirement. All drawn or tie wagers, cancelled or void wagers, or
          wagers on both even-chances during a single game will also not count
          towards any rollover.
          <br />
          2) Should the promotional cashout requirements above not be met within
          30 days from the bonus claimed, the bonus and winnings attributable
          will be forfeited.
          <br />
          3) Unless the promotional cashout requirements are met, the deposit
          amount qualified for the Promotion plus the bonus and any winnings
          attributable are not allowed for withdrawal.
          <br />
          4) The Promotion may not be claimed in conjunction with any other
          promotions currently offered by KKBET.
          <br />
          5) KKBET reserves the right to alter, amend or terminate this
          Promotion, or any aspect of it, at any time and without prior notice.
          All Terms & Conditions (T&C) and Rules applies.
          <br />
          6) The promotion only valid bet of slot game.
          <br />
          7)This promotion is not valid for non-slot game (sport game, live
          game, table game, fishing game and arcade game).
          <br />
          8) If wagered on any non-slot game KKBET has the right to terminate
          the accounts, and credit will be frozen or forfeit permanently.
          <br />
          9) General Terms of Use specified on this site apply to all
          Promotions.
        </p>
      </div>
    );
  }
}

export default ModalText8;
