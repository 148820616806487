import React, { Component } from "react";

// react-bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// images
import img1 from "../../images/games-logo/kk-w_sports.png";
import img2 from "../../images/games-logo/kk-i_sports.png";

class GameMenuSports extends Component {
  state = {};
  render() {
    return (
      <Container fluid>
        <br />
        <Row className="text-center">
          <Col>
            <img src={img1} alt="" className="img-fluid" />
          </Col>
          <Col>
            <img src={img2} alt="" className="img-fluid" />
          </Col>
          <Col>{/* <img src={img} alt="" className="img-fluid" /> */}</Col>
        </Row>
        <br />
      </Container>
    );
  }
}

export default GameMenuSports;
