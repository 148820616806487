import React, { Component, Fragment } from "react";

// components
import NavbarSmall from "./Navbar-sm.js";
import NavbarLarge from "./Navbar-lg.js";
// import HomeLarge from "./Home-lg.js";

import Media from "react-media";
class NavbarController extends Component {
  render() {
    return (
      <div>
        {/* <Media query="(max-width: 599px)" render={() => <Home />} /> */}

        <Media
          queries={{
            sm: "(max-width: 767px)",
            md: "(min-width: 768px) and (max-width: 991px)",
            lg: "(min-width: 992px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.sm && (
                <NavbarSmall changeLanguage={this.props.changeLanguage} />
              )}
              {matches.md && (
                <NavbarSmall changeLanguage={this.props.changeLanguage} />
              )}
              {matches.lg && (
                <NavbarLarge changeLanguage={this.props.changeLanguage} />
              )}
            </Fragment>
          )}
        </Media>
      </div>
    );
  }
}

export default NavbarController;
