import React, { Component } from "react";

// i18n
//import i18n from "../locales/i18n";
import { withNamespaces } from "react-i18next";

// react-bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

// font-awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faWeixin } from "@fortawesome/free-brands-svg-icons";

import wechatQr from "../../images/wechat-qr-image-2.jpeg";

class Contact extends Component {
  constructor(props, context) {
    super(props, context);

    this.modalShow = this.modalShow.bind(this);
    this.modalClose = this.modalClose.bind(this);

    this.state = {
      show: false,
      modalImage: 0,
    };
  }

  modalClose() {
    this.setState({ show: false });
  }

  modalShow(imageNum) {
    this.setState({ show: true });
    this.setState({ modalImage: imageNum });
  }

  render() {
    const { t } = this.props;
    const colStyle = "";

    let content;

    switch (this.state.modalImage) {
      case "1":
        // Phone
        content = <h4>+60 16-672 8924</h4>;
        break;
      case "2":
        // Telegram
        content = (
          <h4
            onClick={(event) => {
              event.preventDefault();
              window.open("https://t.me/kkbetcs88");
            }}
          >
            https://t.me/kkbetcs88
          </h4>
        );
        break;
      case "3":
        // WeChat
        content = <img src={wechatQr} alt="" className="img-fluid " />;
        break;
      case "4":
        // WhatsApp
        content = (
          <h4
            onClick={(event) => {
              event.preventDefault();
              window.open("https://KKVViP1.wasap.my");
            }}
          >
            https://KKVViP1.wasap.my
          </h4>
        );
        break;
    }

    return (
      <div align="center" className="font-family-1">
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.show}
          onHide={this.modalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title className="font-size-12">
              {this.props.t("contact_title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body closeButton>
            {/* <img
              src={modalDisplayImage}
              alt={"promo-" + this.state.modalImage}
              className="img-fluid "
            />
            {modalDisplayText} */}
            <div className="text-center">{content}</div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={this.modalClose}>
              {this.props.t("close")}
            </Button>
            <Button variant="primary" onClick={this.modalClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>

        <Container fluid className="bg-step2 text-center py-4">
          <span className="display-4 text-white font-size-15 font-family-1">
            {this.props.t("contact_title")}
          </span>
        </Container>

        <Container
          fluid
          className="text-white pt-5 height-30vh background-image "
        >
          <Row style={{ display: 'flex', justifyContent: 'center' }}>         
			  {/*<Col
              lg={{ span: 3, offset: 3 }}
              className="py-3"
              onClick={() => this.modalShow("1")}
            >
              <FontAwesomeIcon icon={faPhoneAlt} inverse size="2x" />
              <h5 className="p-2">+60 16-672 8924</h5>
              <span className="">Click Here!</span>
			  </Col>*/}
            <Col
              lg={{ span: 3, offset: 0 }}
              className="p-3"
              onClick={() => this.modalShow("2")}
            >
              <FontAwesomeIcon icon={faTelegram} inverse size="3x" />
              {/* <div className="d-inline-block overflow-auto ml-3 font-size-12">
                <span>Click Here!</span>
              </div> */}
              <br />
              <h5 className="p-2">+60 11-5881 2260</h5>
              <span className="">Click Here!</span>
            </Col>
          </Row>

          <Row>
            <Col
              lg={{ span: 3, offset: 3 }}
              className="p-3"
              onClick={() => this.modalShow("3")}
            >
              <FontAwesomeIcon icon={faWeixin} inverse size="3x" />
              <h5 className="p-2">kkbetcs8</h5>
              <span className="">Click Here!</span>
            </Col>

            <Col
              lg={{ span: 3, offset: 0 }}
              className="p-3"
              onClick={() => this.modalShow("4")}
            >
              <FontAwesomeIcon icon={faWhatsapp} inverse size="3x" />

              <h5 className="p-2">+60 11-5881 2260</h5>
              <span className="">Click Here!</span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  aaa = () => {
    console.log("dsdsd");
  };
}

export default withNamespaces()(Contact);
