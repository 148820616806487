import React, { Component } from "react";

import Table from "react-bootstrap/Table";

class ModalText6 extends Component {
  state = {};
  render() {
    return (
      <div>
        <br />
        <h3>WEEKLY REBATE BONUS</h3>
        <p>
          Get up to DEPOSIT Weekly Rebate for total nett loss amount!!! Member
          must not miss it !!!
        </p>

        <h5>Terms & Conditions</h5>
        <p>
          1) The promotion is open for all KKBET players.
          <br />
          2) Players have to make a minimum total deposit of MYR 100 within
          Monday - Sunday.
          <br />
          3) The promotion is calculate with nett loss within Monday - Sunday
          based on their amount wagered in KKBET slot games only while taking 5%
          unlimited bonus.
          <br />
          4) The rebate will be automatically given to member on every Monday
          4AM
        </p>

        <p>
          How to claim:
          <br />
          (Total Deposit - Total Withdraw)
          <br />
          Example:
          <br />
          - Deposit ID RM 500 dapat claim PT38 MIN 68 MAX 128
          <br />
          - Deposit ID RM 1000 dapat claim PT88 MIN 128 MAX 188
          <br />
          - Deposit ID RM 2000 dapat claim PT128 MIN 188 MAX 288
          <br />- Deposit ID RM 5000 dapat claim PT188 MIN 288 MAX 388
        </p>

        <p>
          Total Deposit MYR 1000 - Total Withdraw MYR 500
          <br />
          Weekly Rebate = (1000 - 500)
          <br />
          Weekly Rebate = MYR 38
          <br />
          Minimum & Maximum cashout requirement = 38 until 68/128 withdraw
        </p>

        <p>
          5) For the purpose of the Promotion, only wagers in KKBET that
          generate a win/loss return will contribute to the rollover
          requirement. All drawn or tie wagers, cancelled or void wagers, or
          wagers on both even-chances during a single game will also not count
          towards any rollover.
          <br />
          6) Should the promotional cashout requirements above not be met within
          30 days from the bonus claimed, the bonus and winnings attributable
          will be forfeited.
          <br />
          7) Unless the promotional cashout requirements are met, the deposit
          amount qualified for the Promotion plus the bonus and any winnings
          attributable are not allowed for withdrawal.
          <br />
          8) The Promotion may not be claimed in conjunction with any other
          promotions currently offered by KKBET.
          <br />
          9) KKBET reserves the right to alter, amend or terminate this
          Promotion, or any aspect of it, at any time and without prior notice.
          All Terms & Conditions (T&C) and Rules applies.
          <br />
          10) The promotion only valid bet of slot game.
          <br />
          11) This promotion is not valid for non-slot game (sport game, live
          game, table game, fishing game and arcade game).
          <br />
          12) If wagered on any non-slot game KKBET has the right to terminate
          the accounts, and credit will be frozen or forfeit permanently.
          <br />
          13) General Terms of Use specified on this site apply to all
          Promotions.
        </p>
      </div>
    );
  }
}

export default ModalText6;
