import React, { Component } from "react";

// i18n
//import i18n from "../locales/i18n";
import { withNamespaces } from "react-i18next";

// images
// import cimbLogo from "../images/logo/cimb-bank-logo.png";
// import maybankLogo from "../images/logo/maybank-logo.png";
// import publicLogo from "../images/logo/public-bank-logo.png";
// import hongleongLogo from "../images/logo/hong-leong-bank-logo.png";
// import rhbLogo from "../images/logo/rhb-bank-logo.png";

// react-bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// font-awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";

// react-router-dom
//import { Link } from "react-router-dom";

class Footer extends Component {
  state = {};
  render() {
    const { t } = this.props;

    return (
      <div>
        <Container
          fluid
          className="bg-dark text-white py-4 text-center font-size-6"
        >
          <Row>
            <Col className="py-2">
              <div className="bg-red text-yellow bold py-2">AmBank</div>
            </Col>
            <Col className="py-2">
              <div className="bg-pink-9 text-white bold py-2">Bank Islam</div>
            </Col>
            <Col className="py-2">
              <div className="bg-cyan-5 bold py-2">BSN Bank</div>
            </Col>
            <Col className="py-2">
              <div className="bg-red bold py-2">CIMB Bank</div>
            </Col>
          </Row>
          <Row>
            <Col className="py-2">
              <div className="bg-blue text-white bold py-2">HLB Bank</div>
            </Col>
            <Col className="py-2">
              <div className="bg-yellow text-black bold py-2">MayBank</div>
            </Col>
            <Col className="py-2">
              <div className="bg-blue-light-a200 text-blue bold py-2">
                RHB Bank
              </div>
            </Col>
            <Col className="py-2">
              <div className="bg-white text-red bold py-2">Public Bank</div>
            </Col>
          </Row>
        </Container>

        <Container fluid className="bg-gray-8 py-2">
          <Container className="text-center text-white py-2 font-size-9">
            <span>
              {this.props.t("copyright")}
              <FontAwesomeIcon
                icon={faCopyright}
                inverse
                // className="font-size-4"
              />
              2020 KK Casino
            </span>
          </Container>
          <br />
          <br />
          <br />
        </Container>
      </div>
    );
  }
}

export default withNamespaces()(Footer);
