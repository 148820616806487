import React, { Component, Fragment } from "react";

// components
import HomeSmall from "./Home-sm.js";
import HomeLarge from "./Home-lg.js";

import Media from "react-media";
class HomeController extends Component {
  state = {
    gameMenu: 1,
  };

  render() {
    const changeGameMenu = (menuNo) => {
      this.setState({ gameMenu: menuNo });
    };

    return (
      <div>
        {/* <Media query="(max-width: 599px)" render={() => <Home />} /> */}

        <Media
          queries={{
            sm: "(max-width: 767px)",
            md: "(min-width: 768px) and (max-width: 991px)",
            lg: "(min-width: 992px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.sm && (
                <HomeSmall
                  changeGameMenu={changeGameMenu}
                  gameMenu={this.state.gameMenu}
                />
              )}
              {matches.md && (
                <HomeSmall
                  changeGameMenu={changeGameMenu}
                  gameMenu={this.state.gameMenu}
                />
              )}
              {matches.lg && (
                <HomeLarge
                  changeGameMenu={changeGameMenu}
                  gameMenu={this.state.gameMenu}
                />
              )}
            </Fragment>
          )}
        </Media>
      </div>
    );
  }
}

export default HomeController;
