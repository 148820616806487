import React, { Component } from "react";

import Table from "react-bootstrap/Table";

class ModalText3 extends Component {
  state = {};
  render() {
    return (
      <div>
        <br />
        <h3>Daily rebate 5% slot bonus</h3>
        <p>Daily claim extra free Before next day 1.00AM-4:00AM</p>

        <h5>Terms & Conditions</h5>
        <p>
          1) The promotion is open for all KKBET players.
          <br />
          2) Players have to make a minimum total deposit of MYR 50 within a
          day.
          <br />
          3) The promotion is calculate with nett loss within a day based on
          their amount wagered in KKBET slot games only while taking 5%
          unlimited bonus.
          <br />
          4) Rebates will be calculated nett lose amount and automatically given
          to member after results announced.
          <br />
          5) This promotion is only Monday - Saturday
        </p>

        <p>
          How to claim:
          <br />
          (Total Deposit - Total Withdraw) x 5%
          <br />
          Example:
          <br />
          Total Deposit MYR 1000 - Total Withdraw MYR 300
          <br />
          Daily Rebate = (1000 - 300) x 5%
          <br />
          Daily Rebate = MYR 35 (Rebete over MYR68 will Direct Bank In)
          <br />
          Minimum cashout requirement = 35 until 68 Withdraw
          <br />
          Max & Min Withdraw RM 68
        </p>

        <p>
          5) For the purpose of the Promotion, only wagers in KKBET that
          generate a win/loss return will contribute to the rollover
          requirement. All drawn or tie wagers, cancelled or void wagers, or
          wagers on both even-chances during a single game will also not count
          towards any rollover.
          <br />
          6) Should the promotional cashout requirements above not be met within
          30 days from the bonus claimed, the bonus and winnings attributable
          will be forfeited.
          <br />
          7) Unless the promotional cashout requirements are met, the deposit
          amount qualified for the Promotion plus the bonus and any winnings
          attributable are not allowed for withdrawal.
          <br />
          8) The Promotion may not be claimed in conjunction with any other
          promotions currently offered by KKBET.
          <br />
          9) KKBET reserves the right to alter, amend or terminate this
          Promotion, or any aspect of it, at any time and without prior notice.
          All Terms & Conditions (T&C) and Rules applies.
          <br />
          10) The promotion only valid bet of slot game.
          <br />
          11) This promotion is not valid for non-slot game (sport game, live
          game, table game, fishing game and arcade game).
          <br />
          12) If wagered on any non-slot game KKBET has the right to terminate
          the accounts, and credit will be frozen or forfeit permanently.
          <br />
          13) General Terms of Use specified on this site apply to all
          Promotions.
        </p>
      </div>
    );
  }
}

export default ModalText3;
