import React, { Component } from "react";

// react-bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// images
import img1 from "../../images/games-logo/kk-gss_suite.png";
import img2 from "../../images/games-logo/kk-pt_suite.png";
import img3 from "../../images/games-logo/kk-live22_suite.png";
import img4 from "../../images/games-logo/kk-pp_suite2.png";
import img5 from "../../images/games-logo/kk-gp_suite.png";
import img6 from "../../images/games-logo/kk-ag_suite.png";
import img7 from "../../images/games-logo/kk-mega_suite.png";
import img8 from "../../images/games-logo/kk-fg_suite.png";
import img9 from "../../images/games-logo/kk-918_suite.png";

class GameMenuSlots extends Component {
  state = {};
  render() {
    return (
      <Container fluid>
        <br />
        <Row className="text-center">
          <Col>
            <img src={img1} alt="" className="img-fluid" />
          </Col>
          <Col>
            <img src={img2} alt="" className="img-fluid" />
          </Col>
          <Col>
            <img src={img3} alt="" className="img-fluid" />
          </Col>
        </Row>
        <br />

        <Row className="text-center">
          <Col>
            <img src={img4} alt="" className="img-fluid" />
          </Col>
          <Col>
            <img src={img5} alt="" className="img-fluid" />
          </Col>
          <Col>
            <img src={img6} alt="" className="img-fluid" />
          </Col>
        </Row>
        <br />

        <Row>
          <Col>
            <img src={img7} alt="" className="img-fluid" />
          </Col>
          <Col>
            <img src={img8} alt="" className="img-fluid" />
          </Col>
          <Col>
            <img src={img9} alt="" className="img-fluid" />
          </Col>
        </Row>
        <br />
      </Container>
    );
  }
}

export default GameMenuSlots;
