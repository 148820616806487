import React, { Component } from "react";

import Table from "react-bootstrap/Table";

class ModalText7 extends Component {
  state = {};
  render() {
    return (
      <div>
        <br />
        <h3>20% Daily Reload Bonus</h3>

        <Table bordered hover>
          <thead>
            <tr>
              <th>Minimum Deposit</th>
              <th>Bonus</th>
              <th>Minimum Cashout</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MYR 30</td>
              <td>20%</td>
              <td>x3</td>
            </tr>
          </tbody>
        </Table>

        <h5>Terms & Conditions</h5>
        <p>
          1) The promotion is open for all KKBET players.
          <br />
          2) Players have to make a minimum deposit of MYR 30.
          <br />
          3) Maximum claim for this promotion is MYR 300.
          <br />
          4) The Promotion is subjected to a 3 time(s) minimum cashout
          requirement before any withdrawal can be made.
          <br />
          5) Only For Yesterday Deposit Daily Claim
        </p>
      </div>
    );
  }
}

export default ModalText7;
