import React, { Component, Fragment } from "react";

// components
import BottomMenu from "./BottomMenu-sm.js";
// import HomeLarge from "./Home-lg.js";

import Media from "react-media";
class BottomMenuController extends Component {
  state = {
    menu: 1,
  };

  render() {
    const changeMenu = (menuNo) => {
      this.setState({ menu: menuNo });
    };

    return (
      <div>
        {/* <Media query="(max-width: 599px)" render={() => <Home />} /> */}

        <Media
          queries={{
            sm: "(max-width: 767px)",
            md: "(min-width: 768px) and (max-width: 991px)",
            lg: "(min-width: 992px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.sm && (
                <BottomMenu changeMenu={changeMenu} menu={this.state.menu} />
              )}
              {matches.md && (
                <BottomMenu changeMenu={changeMenu} menu={this.state.menu} />
              )}
              {matches.lg && (
                <BottomMenu changeMenu={changeMenu} menu={this.state.menu} />
              )}
            </Fragment>
          )}
        </Media>
      </div>
    );
  }
}

export default BottomMenuController;
