import React, { Component } from "react";

import Table from "react-bootstrap/Table";

class ModalText1 extends Component {
  state = {};
  render() {
    return (
      <div>
        <br />
        <h3>100% Welcome Bonus</h3>
        <p>
          MS GAME NEW PLAYER 100% WELCOME BONUS <br />
          Enjoy total 100% New Player Bonus up to MYR 300 with your initial
          deposit made in KKBET! Join Now!
        </p>
        <Table bordered hover>
          <thead>
            <tr>
              <th>Deposit</th>
              <th>Bonus + (100%)</th>
              <th>Max/Minimum Cashout (x6)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MYR 50</td>
              <td>100</td>
              <td>600</td>
            </tr>
            <tr>
              <td>MYR 100</td>
              <td>200</td>
              <td>1200</td>
            </tr>
            <tr>
              <td>MYR 200</td>
              <td>400</td>
              <td>2400</td>
            </tr>
            <tr>
              <td>MYR 300</td>
              <td>600</td>
              <td>3600</td>
            </tr>
          </tbody>
        </Table>

        <h5>Terms & Conditions</h5>
        <p>
          1) Deposit minimum of MYR 50 or above will get 100% bonus up to MYR
          300 on the first deposit.
          <br />
          2) Only new players are eligible for this bonus.
          <br />
          3) The Promotion is subjected to a 6 time(s) minimum cashout
          requirement before any withdrawal can be made.
          <br />
          4) No Turnover and Maximun Cashout
        </p>

        <p>
          Example:
          <br />
          First Deposit = MYR 100
          <br />
          100% Free Bonus = MYR 100
          <br />
          Minimum & Maximum cashout requirement = (MYR 100 + MYR 100) Rollover x
          6 = MYR 1200
          <br />
          Rollover = Betting Spin
          <br />
          <span className="font-italic">
            *Please contact our customer care representatives after you had made
            your deposit.
          </span>
        </p>

        <p>
          5) For the purpose of the Promotion, only wagers in KKBET that
          generate a win/loss return will contribute to the rollover
          requirement. All drawn or tie wagers, cancelled or void wagers, or
          wagers on both even-chances during a single game will also not count
          towards any rollover.
          <br />
          6) Should the promotional cashout requirements above not be met within
          30 days from the bonus claimed, the bonus and winnings attributable
          will be forfeited.
          <br />
          7) Unless the promotional cashout requirements are met, the deposit
          amount qualified for the Promotion plus the bonus and any winnings
          attributable are not allowed for withdrawal.
          <br />
          8) The Promotion may not be claimed in conjunction with any other
          promotions currently offered by KKBET.
          <br />
          9) KKBET reserves the right to alter, amend or terminate this
          Promotion, or any aspect of it, at any time and without prior notice.
          All Terms & Conditions (T&C) and Rules applies.
          <br />
          10) The promotion only valid bet of slot game.
          <br />
          11) This promotion is not valid for non-slot game (sport game, live
          game, table game, fishing game and arcade game).
        </p>

        <p>
          If wagered on any non-slot game KKBET has the right to terminate the
          accounts, and credit will be frozen or forfeit permanently.
        </p>
      </div>
    );
  }
}

export default ModalText1;
