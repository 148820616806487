import React, { Component, Fragment } from "react";

// components
import FooterSmall from "./Footer-sm.js";
import FooterLarge from "./Footer-lg.js";
// import HomeLarge from "./Home-lg.js";

import Media from "react-media";
class FooterController extends Component {
  render() {
    return (
      <div>
        {/* <Media query="(max-width: 599px)" render={() => <Home />} /> */}

        <Media
          queries={{
            sm: "(max-width: 767px)",
            md: "(min-width: 768px) and (max-width: 991px)",
            lg: "(min-width: 992px)",
          }}
        >
          {(matches) => (
            <Fragment>
              {matches.sm && <FooterSmall />}
              {matches.md && <FooterLarge />}
              {matches.lg && <FooterLarge />}
            </Fragment>
          )}
        </Media>
      </div>
    );
  }
}

export default FooterController;
