import React, { Component } from "react";

import Table from "react-bootstrap/Table";

class ModalText9 extends Component {
  state = {};
  render() {
    return (
      <div>
        <br />
        <h3>10% Unlimited Reload Bonus</h3>

        <Table bordered hover>
          <thead>
            <tr>
              <th>Deposit</th>
              <th>Bonus + (50%)</th>
              <th>Max/Minimum Cashout (x3)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MYR 30</td>
              <td>10%</td>
              <td>x2</td>
            </tr>
          </tbody>
        </Table>

        <h5>Terms & Conditions</h5>
        <p>
          1) The promotion is open for all KKBET players. <br /> 2) Players have
          to make a minimum deposit of MYR 30. <br /> 3) Maximum claim for this
          promotion is MYR 388. <br /> 4) The Promotion is subjected to a 2
          time(s) minimum cashout requirement before any withdrawal can be made.
        </p>

        <p>
          Example:
          <br />
          Deposit = MYR 200
          <br />
          10% Free Bonus = MYR 20
          <br />
          Minimum cashout requirement = (MYR 200 + MYR 20) x 2 = MYR 440
          <br />
          <span className="font-italic">
            *Please contact our customer care representatives after you had made
            your deposit.
          </span>
        </p>

        <p>
          5) For the purpose of the Promotion, only wagers in KKBET that
          generate a win/loss return will contribute to the rollover
          requirement. All drawn or tie wagers, cancelled or void wagers, or
          wagers on both even-chances during a single game will also not count
          towards any rollover.
          <br />
          6) Should the promotional cashout requirements above not be met within
          30 days from the bonus claimed, the bonus and winnings attributable
          will be forfeited.
          <br />
          7) Unless the promotional cashout requirements are met, the deposit
          amount qualified for the Promotion plus the bonus and any winnings
          attributable are not allowed for withdrawal.
          <br />
          8) The Promotion may not be claimed in conjunction with any other
          promotions currently offered by KKBET.
          <br />
          9) KKBET reserves the right to alter, amend or terminate this
          Promotion, or any aspect of it, at any time and without prior notice.
          All Terms & Conditions (T&C) and Rules applies.
          <br />
          10) The promotion only valid bet of slot game.
          <br />
          11)This promotion is not valid for non-slot game (sport game, live
          game, table game, fishing game and arcade game).
          <br />
          12) If wagered on any non-slot game KKBET has the right to terminate
          the accounts, and credit will be frozen or forfeit permanently.
          <br />
          13) General Terms of Use specified on this site apply to all
          Promotions.
        </p>
      </div>
    );
  }
}

export default ModalText9;
